.search-bar__container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.4rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.search-bar__search {
  display: flex;
  align-items: center;
}

.search-bar__sub-container {
  display: flex;
  align-items: flex-end;
}

.search-bar__button {
  color: #000000;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  border: 1px solid #00c3ff;
  box-sizing: border-box;
  border-radius: 5px;
  background: linear-gradient(169.93deg,
      #a6f2ff -42.12%,
      #48bdfa 204.32%) !important;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.search-bar__input {
  margin-right: 0.8rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header__logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.header__logo {
  margin-left: 1rem;
}

.header__heading {
  font-size: 2.3rem;
  font-weight: bold;
  color: #1f3366;
}

.previous-scan__container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-icon {
  margin-left: 0.2rem;
}

.export__button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
  color: #27736D;
  justify-content: center;
}

@media screen and (min-width: 760px) {
  .search-bar__container {
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.4rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }
}

.MuiFormControl-root {
  margin: 30px 0 !important;
}

.MuiFormControl-root.date_drop_down {
  min-width: 360px !important;
  margin-right: 0px !important;
}
code[class*=language-],
pre[class*=language-] {
    white-space: pre-wrap;
    word-break: break-word;
}

.err-instance-heading {
    text-align: left;
    margin: 0;
    padding: 0;
    line-height: 10px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 0.9rem;
    color: #1f3366;
    font-weight: 700;
}